<template>
  <div>
    <div class="title-blue-bg">
      Are You<br>
      greener than a<br>
      7-Year-Old?!
    </div>
    <p class="text-center subtitle-text">How hard can it be… Right? 😬</p>
    <div class="white-content-bg"></div>
    <div class="white-content">
      <div id="styledElement">
        <b-container id="resizeElement">
          <div class="avatar-holder">
            <div>
              <b-row>
                <b-col>
                  <p class="txt">Complete the below to start the challenge</p>
                  <div class="input-holder">
                  <b-form-input @keyup="resetErrors(); removeSpaces($event)" v-model="username" placeholder="Enter a username" :state="checkValid ? validUsername : null"></b-form-input>
                  <b-form-invalid-feedback v-show="!validUsername" id="input-live-feedback">
                    <span v-if="srvMessage">{{ srvMessage }}</span>
                    <span v-else>Please type a valid username</span>
                  </b-form-invalid-feedback>
                  </div>
                  <p class="customize-text">customise YOUR
                    CHANGEmaker avatar</p>
                  <p class="txt" style="margin-top: 5px; margin-bottom: 0">Because if you want to THINK like a<br>
                    10-year-old, you need to ACT like one, too.</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="avatar-picker" style="margin-top: -10px">
                  <div class="main-avtar">
                    <div class="avtar-content">
                      <div class="avtar-wrap">
                        <div class="bg" v-if="selectedAvatar"><img :src="selectedAvatar"/></div>
                      </div>
                    </div>
                    <div class="avatar-arrow prev prev-head" @click="prev()">
                      <img src="../assets/img/left-arrow1.png" alt="left-arrow">
                    </div>
                    <div class="avatar-arrow next next-head" @click="next()">
                      <img src="../assets/img/right-arrow1.png" alt="right-arrow">
                    </div>
                    <div class="button-group text-center" style="margin-top: 4px; margin-bottom: 0;">
                      <b-button class="save-avatar play-btn" @click="play()">PLAY NOW!</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import avatars from '../avatars'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AvatarSelector',
  data () {
    return {
      avatars,
      selectedAvatar: avatars[0],
      colors: [],
      username: '',
      checkValid: false,
      srvMessage: false
    }
  },
  methods: {
    ...mapActions(['setUser', 'resetUser']),
    resetErrors () {
      this.srvMessage = false
      this.checkValid = false
    },
    removeSpaces (e) {
      this.username = this.username.replace(/\s*/g, '')
    },
    next () {
      const idx = this.avatars.indexOf(this.selectedAvatar)
      if ((idx + 1) === this.avatars.length) {
        this.selectedAvatar = this.avatars[0]
      } else {
        this.selectedAvatar = this.avatars[idx + 1]
      }
    },
    prev () {
      const idx = this.avatars.indexOf(this.selectedAvatar)
      if (idx <= (this.avatars.length - 1) && idx !== 0 && idx !== -1) {
        this.selectedAvatar = this.avatars[idx - 1]
      } else {
        if (idx === 0 || idx === -1) {
          this.selectedAvatar = this.avatars[this.avatars.length - 1]
        }
      }
    },
    play () {
      this.checkValid = true
      if (this.validUsername) {
        this.setUser({ username: this.username, avatar: this.avatars.indexOf(this.selectedAvatar) }).then(data => {
          if (data.msg) {
            this.srvMessage = false
            this.$router.push({ name: 'Quiz' })
          } else if (data.message) {
            this.srvMessage = data.message
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters(['userName', 'score']),
    validUsername () {
      const patt = new RegExp(/^[-A-Za-z]*$/gm)
      return patt.test(this.username) && this.username.length >= 3 && this.username.length <= 35 && !this.srvMessage
    }
  },
  mounted () {
    if (this.userName.length > 0 && this.score === null) {
      this.$router.push({ name: 'Quiz' })
    }

    if (this.userName.length && this.score !== null) {
      this.resetUser()
    }
  }
}
</script>

<style scoped>
.customize-head-left {
  right: 75%;
  z-index: 3;
  top: -3px;
  position: absolute;
}

.customize-head-right {
  position: absolute;
  right: 22%;
  z-index: 3;
  top: -8px;
}

.col img {
  max-width: 200px
}

.white-content .head1 h3 {
  background: url(../assets/img/avatar-head2-3022@2x.png) bottom left no-repeat;
  padding: 12px 12px;
  color: #fff;
  margin-bottom: 0;
  display: inline-block;
  margin-left: 16%;
  margin-top: -10px;
  transform: rotate(1deg);
  position: relative;
  z-index: 0;
  font-style: italic;
  text-transform: uppercase;
  font-size: 30px;
  background-size: 100% 100%;
  height: 54px;
  width: 510px;
  line-height: 28px;
  text-align: center;
  font-family: 'TjardaHand-Italic';
  font-weight: 400;
  letter-spacing: -2px;
}

.custom-avtar .prev, .custom-avtar .next {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-avtar .prev {
  position: absolute;
  left: 0;
}

.custom-avtar .next {
  position: absolute;
  right: 0;
}

.custom-avtar .prev.prev-head {
  top: 20px;
  left: 30%;
}

.custom-avtar .prev.prev-nose {
  top: 75px;
  left: 30%;
}

.custom-avtar .prev.prev-feet {
  top: 130px;
  left: 30%;
}

.custom-avtar .next.next-head {
  top: 20px;
  right: 30%;
}

.custom-avtar .next.next-nose {
  top: 75px;
  right: 30%;
}

.custom-avtar .next.next-feet {
  top: 130px;
  right: 30%;
}

.avtar-wrap {
  width: 200px;
  position: relative;
  min-height: 200px;
  margin: auto;
}

.avtar-wrap > div {
  position: absolute;
  /* left: 50%;
  transform: translateX(-50%); */
  max-width: 200px;
  width: 100%;
}

.choose-tool-text {
  position: absolute;
  right: 270px;
  top: 35px;
}

.choose-color-text {
  position: absolute;
  right: 280px;
  top: 15px;
}

.avtar-wrap img {
  width: 100%;
}

.color-selection {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: relative
}

.color-selection ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.color-selection ul li {
  height: 33px;
  width: 33px;
  border: 1px solid #7b7b7b;
  border-radius: 100%;
  margin: 5px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.color-selection ul li.active:before {
  content: "";
  height: 39px;
  width: 39px;
  background-color: transparent;
  border: 2px solid #f29200;
  position: absolute;
  top: -4px;
  left: -4px;
  border-radius: 100%;
}

.choose-color {
  position: relative;
  max-width: 250px;
  margin: 20px auto;
}

.choose-color figure {
  max-width: 110px;
  margin: 0 auto;
}

.choose-color .prev, .choose-color .next {
  top: 50%;
  transform: translateY(-50%);
}

.choose-color figure img {
  max-width: 100%;
}

.button-group button {
  width: 190px;
  text-align: center;
  border: 0;
  padding: 10px 10px;
  font-weight: bold;
  font-size: 20px;
  outline: none !important;
}

.button-group {
  margin-bottom: 30px;
}

.button-group button.white-btn {
  color: #00ab4f;
  background: url(../assets/img/avatar-btn1.png) bottom left no-repeat;
  margin-right: 10px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
}

.button-group button.green-btn {
  color: #fff;
  background: url(../assets/img/avatar-btn2.png) bottom left no-repeat;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
}

.custom-avtar {
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  background-size: 100% 100%;
}

.main-div {
  background-image: url(../assets/img/avatar-grey-splash.png);
  margin: auto;
  background-size: 100% 100%;
}

.main-div .container {
  min-height: 647px;
}

.color-picker {
  background-color: white;
  padding: 5px;
  height: 55px;
}

.customize-head {
  position: relative;
  background-size: 100% 100%;
}

@media only screen and (max-width: 767px) {
  .customize-head-left {
    right: 90%;
    z-index: 3;
    top: -5px;
    position: absolute;
  }

  .customize-head-right {
    position: absolute;
    right: 0;
    z-index: 3;
    top: -8px;
  }

  .main-avtar {
    max-width: 300px;
  }

  .avtar-wrap > div {
    max-width: 150px;
  }

  .avtar-wrap {
    width: 200px;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button-group .green-btn {
    margin-top: 10px;
  }

  .tool-title {
    font-size: 17px;
    color: #00AB4F;
    font-weight: 800;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    letter-spacing: -0.3px;
  }

  .white-content .head1 h3 {
    width: auto;
    height: auto;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .main-div {
    min-height: 720px;
    maxi-width: 1440px;
  }

  .color-picker {
    margin-bottom: 27px;
  }

  .choose-color-text {
    right: 84px !important;
    top: 60px !important;
  }

  .choose-color {
    max-width: 240px;
  }

  .button-group {
    margin-top: 60px;
  }

  .choose-tool-text {
    position: absolute;
    right: 25px !important;
    bottom: -40px;
    top: inherit;
  }
}

</style>
